import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import qs from "qs";
import { urlLogin } from "@/api/login";
import JSONBig from "json-bigint";
import store from "@/store/index";
Vue.use(VueRouter)
const routes = [
  {
    path: '/', redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home')
  },
  {
    path: '/listpage',
    name: 'listpage',
    component: () => import('@/views/listpage')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login')
  },
  {
    path: '/videoPlay',
    name: 'videoPlay',
    component: () => import('@/views/videoPlay')
  },
  {
    path: '/collection',
    name: 'collection',
    component: () => import('@/views/collection')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/history')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/map')
  },
]


const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
    return
  }
  const customerId = Cookies.get('customerId');
  if (customerId) {
    next()
  } else {
    var tokenAuth = to.query.tokenAuth;
    var data = qs.stringify({
      tokenAuth: tokenAuth
    });
    urlLogin(data)
      .then((res) => {
        if (res.data.code == 200) {
          Cookies.set(
            "customerId",
            JSONBig.stringify(res.data.data)
          );
          router.push("/home");
        }
        if (res.data.code == 500) {
          store.commit("setMessage", res.data.message);
          router.push("/login");

        }
        if (res.data.code == 600) {
          router.push("/login");
        }
      });
  }
})
export default router
