
import request from '@/utils/request'


export function login(data) {
    return request({
        method: 'POST',
        url: `/cusLogin/login`,
        data
    })
}
// ip和链接登录
export function urlLogin(data) {
    return request({
        method: 'POST',
        url: `/cusLogin/urlLogin`,
        data
    })
}
