import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

import {getToken,setToken,removeToken,setUserId} from '@/utils/auth'
import {login,urlLogin} from '@/api/login'
import {Message} from 'element-ui'
import JSONBig from "json-bigint";

Vue.use(Vuex)

// const token = 'MjUyODYxMTI3ODcxMDQxNTM2LTE2MTg0NTY4MTQ='
// 如果/home?MjUyODYxMTI3ODcxMDQxNTM2LTE2MTg0NTY4MTQ=有跟就解析出来，
export default new Vuex.Store({
  state: {
    tokenAuth: '',
    customerId: undefined,
    messageIp: ''
  },
  mutations: {
    setToken(state,payload) {
      state.tokenAuth=payload
      setToken(payload)
    },
    setUserId(state,payload) {
      state.customerId=payload
      setUserId(payload)
    },
    setMessage(state, payload) {
      console.log(payload);
      state.messageIp = payload
    }
    // removeToken(state){
    //   state.tokenAuth = undefined
    //   removeToken()
    // }
  },
  actions: {
    async login(context,data) {
      const res=await login(data)
      // context.commit('setToken',tokenAuth)
      // console.log(res);
      if (res.data.code !== 200) {
        return Message.error("登录失败");
      }else{
        Message.success("登录成功")
        context.commit("setUserId", JSONBig.stringify(res.data.data))
        router.push('/home')
        if(res.data.tokenAuth){
          context.commit("setToken",token)
        }else{
          return res
        }
      }
    },
    // async urlLogin(context,data) {
      
    // }
  },
})
