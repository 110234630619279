import axios from 'axios'
import JSONBig from 'json-bigint'
import Cookies from 'js-cookie'

const instance = axios.create({
  // res.setHeader('Access-Comtril-Allow-Headers','Content-Type,X-Custom-Header'),
  headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' },
  baseURL: 'https://lsgsk.cxcwwlkj.com:6699/',
  // baseURL:'http://192.16s8.0.147:6699/',
  // baseURL:'http://127.0.0.1:6699/',
  // baseURL:'http://192.168.0.115:6699/',
  // baseURL:window.PLATFROM_CONFIG.baseUrl,

  // 自定义原始相应的数据
  transformResponse: [function (data) {
    try {
      return JSONBig.parse(data)
    } catch (err) {
      return data // 如果data不是json数据,直接return data
    }
  }]
})

// 请求拦截器 统一注入token ActiveId CustomerId
instance.interceptors.request.use(function (config) {
  // 发送请求之前做的
  config.headers.CustomerId = Cookies.get('customerId')
  return config
}, function (error) {
  // 对请求错误做的
  return Promise.reject(error)
})

export default instance


export const gethttp = (url) => {
  return new Promise((resolve, reject) => {
    instance({
      methods: "get",
      url: url,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    })
      .then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      )
      .catch(error => {
        reject(error);
      });
  });
};
export const posthttp = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: "post",
      url: url,
      data: params,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
      .then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      )
      .catch(error => {
        reject(error);
      });
  });
};




