import Cookies from 'js-cookie'


// 获取
export function getToken() {
    return Cookies.get('tokenAuth')
}
// 设置
export function setToken(token) {
    return Cookies.set('tokenAuth',token)
}
// 删除
export function removeToken() {
    return Cookies.remove('tokenAuth')
}

export function setUserId(id){
    return Cookies.set('customerId',id)
}
export function getUserId(){
    return Cookies.get('customerId')
}
  