import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.css'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './plugins/element.js'
import Cookies from 'js-cookie'
import "jquery/dist/jquery.min";
// import "popper.js/dist/popper.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import Video from 'video.js';
import qs from 'qs'
import JSONBig from "json-bigint";

import 'video.js/dist/video-js.css';
import './utils/rem'
import 'lib-flexible/flexible'


Vue.use(ElementUI);
Vue.prototype.$JSONBig = JSONBig
Vue.prototype.$Cookies=Cookies
Vue.prototype.$axios=axios
Vue.config.productionTip=false
Vue.prototype.$video=Video
Vue.prototype.$qs=qs
axios.defaults.withCredentials=true;//让ajax携带cookie

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
